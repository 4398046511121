<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path:'/guidanceManage' }">导诊管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path:'/zzManage',query:{name:bwname,id:bwid} }">{{bwname}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{zzname}}</el-breadcrumb-item>
            <el-breadcrumb-item>疾病百科</el-breadcrumb-item>
        </el-breadcrumb>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="症状表现" name="0">
                <Editor :desc="form.symptom" :ys="'symptom'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
            <el-tab-pane label="缓解方式" name="1">
                <Editor :desc="form.relieve" :ys="'relieve'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
            <el-tab-pane label="就诊判断" name="2">
                <Editor :desc="form.seedoctor" :ys="'seedoctor'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
            <el-tab-pane label="潜在疾病" name="3">
                <Editor :desc="form.disease" :ys="'disease'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
            <el-tab-pane label="参考文献" name="4">
                <Editor :desc="form.reference" :ys="'reference'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
            <el-tab-pane label="简要说明" name="5">
                <Editor :desc="form.subtitle" :ys="'subtitle'" v-on:fathertest="fathertest"></Editor>
            </el-tab-pane>
        </el-tabs>
        <p style="height: 50px;"></p>
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="sub">保存</el-button>
        <el-button type="danger" @click="del" :disabled="disabled">删除</el-button>
        </el-form>
    </div>
</template>
<script>
    import Editor from '../components/Editor'
    export default {
        components: {
            Editor
        },
        inject: ['reload'],
        data() {
            return {
                form: {
                    symptom: '',
                    relieve: '',
                    seedoctor: '',
                    disease: '',
                    sym_id: '',
                    reference: '',
                    subtitle: ''
                },
                name: '',
                type: '',
                disabled: false,
                bwname: '',
                zzname: '',
                bwid: '',
                zzid: '',
                type: '',
                activeName: '0'
            }
        },
        mounted() {
            this.bwname = this.$route.query.bwname;
            this.bwid = this.$route.query.bwid;
            this.zzname = this.$route.query.zzname;
            this.zzid = this.$route.query.zzid;
            this.form.sym_id = this.$route.query.zzid;
            this.getzzdetail();
        },
        methods: {
            handleClick(tab, event) {
                // console.log(tab, event);
            },
            fathertest: function (content) {
                // childValue就是子组件传过来的值
                let b = content.b;
                for (var key in this.form) {
                    if (key == b) {
                        this.form[key] = content.a;
                    }
                }
            },
            getzzdetail() {
                this.axios.get('/baike/get_gu_baike_symptom_by_sym_id?sym_id=' + this.zzid)
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            this.type = 'edit';
                            this.form = rs.data.result;
                            this.disabled = false;
                        } else {
                            this.type = 'new';
                            this.disabled = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            cancel() {
                this.$router.push({ path: '/zzManage', query: { name: this.bwname, id: this.bwid } });
            },
            sub() {
                let url = '', str = '';
                if (this.type == 'new') {
                    url = "/baike/insert_gu_baike_symptom";
                    str = '添加成功';
                } else {
                    url = "/baike/update_gu_baike_symptom";
                    str = '修改成功';
                }
                console.log(this.form);
                this.axios.post(url, this.qs.stringify(this.form))
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            this.$message({
                                message: str,
                                type: 'success'
                            });
                            this.$router.push({ path: '/zzManage', query: { name: this.bwname, id: this.bwid } });
                        } else {
                            this.$message(rs.data.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            del() {
                this.$confirm('此操作将永久删除该症状百科, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post('/baike/delete_gu_baike_symptom', this.qs.stringify({ sym_id: this.zzid }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.$router.push({ path: '/zzManage', query: { name: this.bwname, id: this.bwid } });
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
    }
</script>
<style>
    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }

    .ql-container {
        height: 400px;
    }
</style>